var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "head" },
    [
      _c(
        "transition",
        {
          attrs: { name: "expand" },
          on: {
            enter: _vm.enter,
            leave: _vm.leave,
            "after-enter": _vm.afterEnter,
            "after-leave": _vm.afterLeave,
          },
        },
        [
          _vm.showFolderIcon
            ? _c(
                "div",
                {
                  staticClass: "folder expand-target",
                  on: { click: _vm.onFolderIconClick },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/icon/folder_white.png"),
                    },
                  }),
                ]
              )
            : _vm._e(),
        ]
      ),
      _c("div", { staticClass: "title ellipsis" }, [_vm._v(_vm._s(_vm.title))]),
      !_vm.isEditing
        ? _c("playlist-edit-icon", {
            staticClass: "edit",
            attrs: { fillColor: "#474a4d" },
            on: { click: _vm.onEditIconClick },
          })
        : _vm._e(),
      !_vm.isEditing
        ? _c("delete-icon", {
            staticClass: "edit",
            attrs: { fillColor: "#474a4d" },
            on: { click: _vm.onDeleteIconClick },
          })
        : _vm._e(),
      _vm.isEditing
        ? _c(
            "button",
            { staticClass: "edit", on: { click: _vm.onCancelClick } },
            [_vm._v("キャンセル")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }